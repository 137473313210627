import React from 'react';
import styled from '@emotion/styled';
import mediaqueries from '@narative/gatsby-theme-novela/src/styles/media';
import { useColorMode } from 'theme-ui';
import Autograph from './autograph.png';
import AutographWhite from './autograph-white.png';
import { Icon } from '@narative/gatsby-theme-novela/src/types';

const Logo: Icon = () => {
  const [colorMode] = useColorMode();

  return (
    <LogoContainer>
      <img
        width="150"
        alt="auto"
        src={colorMode === 'dark' ? AutographWhite : Autograph}
      />
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }
  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
